import React from "react";
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled";

import ArtistList from "../components/ArtistList";
import ContentListItem from "../components/ContentListItem"
import getSongNumberText from "../functions/getSongNumberText"
import Header from "../components/Header";
import Footer from "../components/Footer"
import ArtistHelmet from "../components/ArtistHelmet";

const Container = styled.div`
  padding: 0 8px;
  max-width: 960px;
  margin: auto;
`

const Artists = ({ pageContext: { name, twitterId, contents } }) => {
  const data = useStaticQuery(graphql`
    query {
      allArtistsJson {
        edges {
          node {
            name
            twitterId
            contents {
              title
            }
          }
        }
      }
    }
  `)
  const { edges } = data.allArtistsJson;
  const filteredEdges = edges.filter(({ node }) => node.twitterId !== twitterId)
  const filteredContents = contents.filter(({ isPublic }) => isPublic)

  const songNumberText = getSongNumberText(filteredContents.length)
  return (
    <Container>
      <ArtistHelmet name={name} twitterId={twitterId} />
      <Header />

      <article>
        <h1>{name}さんがおすすめする音楽{songNumberText}</h1>
        <p>{name}さん(<a href={`https://twitter.com/${twitterId}`} target="_blank" rel="noopener noreferrer">@{twitterId}</a>)がTwitterで言及した動画を{songNumberText}紹介します！</p>

        {filteredContents.map((content, index) => <ContentListItem key={content.tweetId} index={index} content={content}/>)}

      </article>

      <ArtistList edges={filteredEdges} title="他のアーティスト一覧" />

      <Footer />

    </Container>
  );
}

export default Artists
