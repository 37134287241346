import React, { useEffect, useState } from 'react'
import styled from "@emotion/styled"
import useMedia from 'use-media'

const YOUTUBE_EMBED_URL = "https://www.youtube.com/embed/:videoId"

const Section = styled.section`
  padding: 24px 0;
  @media screen and (min-width: 600px) {
    padding: 48px 0;
  }
`
const H2 = styled.h2`
`
const P = styled.p`
  margin: 0;

  padding: 8px 0 16px;
  @media screen and (min-width: 600px) {
    padding: 16px 0 32px;
  }
`
const YoutubeContainer = styled.div`
  text-align: center;
`

const ContentListItem = ({ index, content: { title, text, videoId } }) => {
  const isWide = useMedia({ minWidth: '560px' });
  const [size, setSize] = useState({ width: '560', height: '315' });

  useEffect(() => {
    if (isWide) {
      setSize({ width: '560', height: '315' });
      return
    }
    const width = '100%'
    const height =  (window?.innerWidth - 16) * 315 / 560
    setSize({ width, height });
  }, [isWide])

  const youtubeUrl = YOUTUBE_EMBED_URL.replace(":videoId", videoId)

  return (
    <Section>
      <H2>{index + 1}. {title}</H2>
      <P>{text}</P>

      <YoutubeContainer>
        <iframe
          loading="lazy"
          title={title}
          width={size.width}
          height={size.height}
          src={youtubeUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen>
        </iframe>
      </YoutubeContainer>
    </Section>
  )
}

export default ContentListItem
