import React from "react"
import { Helmet } from "react-helmet"

const ArtistHelmet = ({ name, twitterId }) => (
  <Helmet
    htmlAttributes={{
      lang: 'ja',
    }}
  >
    <meta charSet="utf-8" />
    <title>{name}のおすすめ音楽 - RecoMusic</title>
    <link rel="canonical" href={`https://music.search-maps.com/artists/${twitterId}`} />
    <meta name="description" content={`${name}さんが聞いている音楽は？SNSでおすすめしている音楽をまとめました！みなさんの音楽ライフがよりよいものになりますように。`} />
  </Helmet>
)

export default ArtistHelmet
